import { useContext } from 'react';
import styles from '../styles/Footer.module.scss';
import { Link } from 'react-router-dom';
import { ReactComponent as S3CLogo } from '../assets/images/logos/s3c-logo-white.svg';
import { ReactComponent as TwitterLogo } from '../assets/images/icons/x-icon.svg';
import { ReactComponent as FacebookLogo } from '../assets/images/icons/facebook-logo.svg';
import { ReactComponent as LinkedinLogo } from '../assets/images/icons/linkedin-logo.svg';
import PageContext from '../utils/PageContext';

const Footer = () => {
  const page = useContext(PageContext);

  return (
    <div className={styles.footerWrapper} id='footer'>
      <div className={styles.footerTop}>
        <div className={styles.footerLeft}>
          <Link to='/'>
            <S3CLogo className={styles.footerBrand} />
          </Link>
        </div>
        <div className={styles.footerMiddle}>
          <div className={styles.footerNav}>
            <ul className={styles.footerNavLeft}>
              <li>
                <Link
                  to='/who-we-are'
                  onClick={() => page.setCurrentPage('/who-we-are')}
                >
                  Who We Are
                </Link>
              </li>
              <li>
                <Link
                  to='/what-we-do'
                  onClick={() => page.setCurrentPage('/what-we-do')}
                >
                  What We Do
                </Link>
              </li>
              <li>
                <Link
                  to='/where-we-work'
                  onClick={() => page.setCurrentPage('/where-we-work')}
                >
                  Where We Work
                </Link>
              </li>
            </ul>
            <ul className={styles.footerNavRight}>
              {/* <li>
                <Link
                  to='/learn-more'
                  onClick={() => page.setCurrentPage('learnMore')}
                >
                  Learn More
                </Link>
              </li> */}
              <li>
                <a
                  href='https://www.msd.com/terms-of-use/'
                  target='_blank'
                  rel='noreferrer'
                >
                  Terms of Use
                </a>
              </li>
              <li>
                <a
                  href='https://www.msdprivacy.com/'
                  target='_blank'
                  rel='noreferrer'
                >
                  Privacy Policy
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className={styles.footerRight}>
          <p className={styles.footerSocialText}>Join the Conversation</p>
          <div className={styles.footerSocialIcons}>
            <a
              href='https://twitter.com/share?url=https://strengtheningsystemsforsaferchildbirth.com'
              target='_blank'
              rel='noreferrer'
            >
              <TwitterLogo className={styles.footerSocialIcon} />
            </a>
            <a
              href='https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fstrengtheningsystemsforsaferchildbirth.com%2F&amp;src=sdkpreparse'
              target='_blank'
              rel='noreferrer'
            >
              <FacebookLogo className={styles.footerSocialIcon} />
            </a>
            <a
              href='https://www.linkedin.com/shareArticle?mini=true&url=https://saferchildbirthsystems.com/'
              target='_blank'
              rel='noreferrer'
            >
              <LinkedinLogo className={styles.footerSocialIcon} />
            </a>
          </div>
        </div>
      </div>

      <p className={styles.footerBottom}>
        Copyright © 2024 Merck & Co., Inc., Rahway, NJ, USA and its affiliates.
        All rights reserved. <a
                            href="#"
                            className="cookie-settings-link"
                            onClick={(e) => {
                            e.preventDefault();
                            window.OneTrust && window.OneTrust.ToggleInfoDisplay();
                            }}
                        >
                            <u>Cookie Preferences</u>
                        </a>
      </p>
    </div>
  );
};

export default Footer;
